import Vue from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import axios from 'axios'
Vue.prototype.$http = axios

Vue.config.productionTip = false

Vue.filter('cleanUnderscore', function (value) {
  if (!value) return ''
  value = value.toString().replace(/_/g, ' ');

  value = value.charAt(0).toUpperCase() + value.slice(1)
  value = value.substring(0, value.length -1) + value.charAt(value.length - 1).toUpperCase()
  return value;
})


new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
