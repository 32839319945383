import Vue from 'vue'
import Vuex from 'vuex'
import tip from './modules/tip'
import table from './modules/table'
import knockout from './modules/knockout'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    tip,
    table,
    knockout
  }
})
