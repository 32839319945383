import Vue from 'vue'
const state = {
  last16: {},
  knockOutStage: {},
  last16ThirdPlace: [],
  isScheduleMode: true
}

const getters = {
  getLast16: state => state.last16,
  getLast8: state => state.last8,
  getLast16ThirdPlace: state => state.last16ThirdPlace,
  isScheduleMode: state => state.isScheduleMode
}

const actions = {

  addLast16 ({ commit }, data) {
    commit('ADD_LAST_16', data)
  },
  addKnockoutStageItem({ commit }, data) { 
    commit('ADD_KNOCKOUT_STAGE_ITEM', data)
  },
  addThirdPlace({commit}, data) {
    commit('ADD_THIRD_PLACE_TEAM', data)
  },
  clearThirdPlace ({ commit }) {
    commit('CLEAR_THIRD_PLACE')
  },
  changeScheduleMode({commit}, data) {
    commit('CHANGE_SCHEDULE_MODE', data)
  },
  addKnockoutStageNonSchedule({commit}, data) {
    commit('ADD_KNOCKOUT_STAGE_NON_SCHEDULE', data)
  }
}

const mutations = {

  ADD_LAST_16 (state, {index, data}) {
    Vue.set(state.last16, index, data)
    //Flush object if contains
    if(Object.keys(state.knockOutStage).length != 0 && state.knockOutStage.constructor === Object) {
      state.knockOutStage = {};
    }
  },
  ADD_KNOCKOUT_STAGE_ITEM (state, {nextStage, index, data}) {
    
    const exist = (nextStage in state.knockOutStage);
    if(!exist) {
      Vue.set(state.knockOutStage, nextStage, [])
    }
    Vue.set(state.knockOutStage[nextStage], [index], data)

  },
  ADD_THIRD_PLACE_TEAM(state, data) {
    if(data.length <= 4) {
      state.last16ThirdPlace = data
    } 
  },
  CLEAR_THIRD_PLACE(state) {
    state.last16ThirdPlace = []
  },
  CHANGE_SCHEDULE_MODE(state, data) {
    state.isScheduleMode = data;
    //Flush object if contains
    if(Object.keys(state.knockOutStage).length != 0 && state.knockOutStage.constructor === Object) {
      state.knockOutStage = {};
    }
  },
  ADD_KNOCKOUT_STAGE_NON_SCHEDULE(state, {currentStage, data}) {
    Vue.set(state.knockOutStage, currentStage, data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
