import Vue from 'vue'
const state = {
  groupForm: {},
}

const getters = {
  getGroupForm: state => state.groupForm,
}

const actions = {
  addMatchTip ({ commit }, data) {
    commit('ADD_MATCH_TIP', data)
  },
  addGroup ({ commit }, data) {
    commit('ADD_GROUP', data)
  }
}

const mutations = {
  ADD_MATCH_TIP (state, {group_name, match_id, value}) {
    
    //If group dos not yet exist
    if (typeof state.groupForm[group_name] == "undefined") {
      Vue.set(state.groupForm, group_name, {["match_" + match_id]: value})
    } 
    else {
      state.groupForm[group_name] = Object.assign({}, state.groupForm[group_name], {["match_" + match_id]: value})
    }

    //Vue.set(state.groupForm, group_name, {["match" + match_id]: value})
    //https://gist.github.com/DawidMyslak/2b046cca5959427e8fb5c1da45ef7748
  },
  ADD_GROUP (state, value) {
    Vue.set(state.groupForm, value,{})
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
