import Vue from 'vue'
const state = {
  table: {},
}

const getters = {
  getTable: state => state.table,
  getTableById: (state) => (id) => {
    return state.table[id];
  }
}

const actions = {

  addTableGroup ({ commit }, {name, matches}) {
    let teams = [];
    let added_teams = [];
    for(let i=0; i < matches.length; i++) {
      let key = matches[i].awayTeam.id;
      if(!(added_teams.includes(key))) {
        teams.push({
          id: key,
          name: matches[i].awayTeam.name,
          points: 0
        });
        added_teams.push(key);
      }
    }

    commit('ADD_TABLE_GROUP', {name, teams})
  },

  addTablePoints ({ commit }, data) {
    commit('ADD_TABLE_POINTS', data)
  },
  clearTablePoints ({ commit }, data) {
    commit('CLEAR_TABLE_POINTS', data)
  }
  
}

const mutations = {
  ADD_TABLE_GROUP (state, {name, teams}) {
    Vue.set(state.table, name, teams)
  },
  ADD_TABLE_POINTS(state, {name, team_id, points}) {
    const team = state.table[name].find(item => item.id === team_id);
    team.points += points;
  },
  CLEAR_TABLE_POINTS(state, name) {
    for (const property in state.table[name]) {
      state.table[name][property].points = 0;
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
