<template>
    <div>
  
      <div class="v-data-table elevation-1 pa-2" v-if="group_stage">
        <div class="v-data-table__wrapper table-wrapper">
          <table>
            <thead>
              <tr>
                <th v-for="(header, idx) in headers" :key="idx" :class="(header.value == 'name') ? 'text-left pl-2 fixed-column' : 'text-center'" style="font-size:14px; font-weight: normal;">
                  <span>{{header.text}}</span>
                </th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="(tip, idx) in submittedWithPoints" :key="idx">
                    <td v-for="(header, idx) in headers" :key="idx" :class="(header.value == 'name') ? 'text-left pl-2 fixed-column' : 'text-center'" style="font-size:14px;">
                        <p style="margin-bottom: 0px;" v-if="header.value == 'name'">
                            {{ tip.name }}
                        </p>
                        <span v-else-if="header.slot == true">
                            <template v-if="header.value == 'last16'">
                                <v-chip class="ma-1" v-for="(team, teamIdx) in tip.tip['last16']" :key="teamIdx" :color="team.correct ? 'green' : 'default'">
                                    {{$t(team.name)}}
                                </v-chip>
                            </template>
                            <template v-if="header.value == 'BONUS'">
                              <v-chip class="ma-1" v-for="(team, teamIdx) in tip.tip.knockOutStage[header.value]" :key="teamIdx" :color="tip.bonus ? 'green' : 'default'">
                                    <span v-if="team.name">{{$t(team.name)}}</span>
                                    <span v-else>{{ team }}</span>
                                    
                                </v-chip>
                            </template>
                            <template v-else>
                                <v-chip class="ma-1" v-for="(team, teamIdx) in tip.tip.knockOutStage[header.value]" :key="teamIdx" :color="team.correct ? 'green' : 'default'">
                                    <span v-if="team.name">{{$t(team.name)}}</span>
                                    <span v-else>{{ team }}</span>
                                    
                                </v-chip>
                            </template>
                        </span>
                        <p v-else>
                            {{ tip[header.value] }}
                        </p>
                    </td>
                </tr>

            </tbody>
          </table>
        </div>
      </div>
  
    </div>
  </template>
  
  <script>

    export default {
      name: 'LiveStandingsKnockout',
       data() {
        return {
          sortDirection: false,
          sortByMatch: 'points',
          submitted: {},
          group_stage: false,
          last_stage: false,
          quarter_final: false,
          semi_final: false,
        }
      },
      components: {

      },
      computed: {
        headers: function() {
          let header = [{
            text: "Namn",
            value: "name",
            width: 300
          },
          {
            text: "Gruppspel",
            value: "groupPoints",
          },
          {
            text: "8-delsfinal (3p/l)",
            value: "last16",
            slot: true
          },
          {
            text: "Kvartsfinal (6p/l)",
            value: "QUARTER_FINAL",
            slot: true
          },
          {
            text: "Semifinal (12p/l)",
            value: "SEMI_FINAL",
            slot: true
          },
          {
            text: "Final (24p/l)",
            value: "FINAL",
            slot: true
          },
          {
            text: "Vinnare (48p/l)",
            value: "WINNER",
            slot: true
          },
          {
            text: "Skyttekung (32p/s)",
            value: "BONUS",
            slot: true
          },
          {
            text: "Poäng",
            value: "points"
          }
        ];



        

          return header;
        },
        submittedWithPoints: function () {
            return Object.keys(this.submitted).reduce((acc, curr) => {
                
                let totalPoints =  0;

                //<--- Start group stage -->//
                //Loop each group
                let correctMatches = 0;
                Object.keys(this.submitted[curr].tip.groupForm).forEach(group => {
                    //Loop each match
                    Object.keys(this.submitted[curr].tip.groupForm[group]).forEach(match => {

                        //Get match id
                        const matchId = match.replace(/^match_/, '');

                        //Find the result of the match
                        const matchInfo = this.group_stage.matches.find(e => e.id == matchId);

                        //If correct tip
                        if((this.submitted[curr].tip.groupForm[group][match] == "1" && matchInfo.score.winner == "HOME_TEAM") || (this.submitted[curr].tip.groupForm[group][match] == "X" && matchInfo.score.winner == "DRAW") || (this.submitted[curr].tip.groupForm[group][match] == "2" && matchInfo.score.winner == "AWAY_TEAM")) {
                            correctMatches++;
                        }
                    })
                })

                totalPoints += correctMatches * 2;
                //<--- End group stage -->//


                //<--- Start last 16  -->//
                let correctLastStage = 0;
                this.submitted[curr].tip.last16.forEach(tipTeam => {
                    const teamIsQualified = this.last_stage.matches.some(team => {
                        if(team.awayTeam.id == tipTeam.id || team.homeTeam.id == tipTeam.id) {
                            return true
                        }
                        else {
                            return false;
                        }
                    });
                    if(teamIsQualified) {
                        tipTeam.correct = true;
                        correctLastStage++;
                    }
                    else {
                        tipTeam.correct = false;
                    }
                })
                totalPoints += correctLastStage * 3;
                //<--- End last 16 -->//

                //<--- Start Quarter Final -->//
                let correctQuarterFinal = 0;
                this.submitted[curr].tip.knockOutStage["QUARTER_FINAL"].forEach(tipTeam => {
                  const teamIsQualified = this.last_stage.matches.some(team => {
                        if(team.awayTeam.id == tipTeam.id && team.score.winner == 'AWAY_TEAM' || team.homeTeam.id == tipTeam.id && team.score.winner == 'HOME_TEAM') {
                            return true
                        }
                        
                        else {
                            return false;
                        }
                    });

                    if(teamIsQualified) {
                        tipTeam.correct = true;
                        correctQuarterFinal++;
                    }
                    else {
                        tipTeam.correct = false;
                    }

                })
                totalPoints += correctQuarterFinal * 6;
                //<--- End Quarter Final -->//


                //<--- Start SEMI Final -->//
                let correctSemiFinal = 0;
                this.submitted[curr].tip.knockOutStage["SEMI_FINAL"].forEach(tipTeam => {
                  const teamIsQualified = this.quarter_final.matches.some(team => {
                        if(team.awayTeam.id == tipTeam.id && team.score.winner == 'AWAY_TEAM' || team.homeTeam.id == tipTeam.id && team.score.winner == 'HOME_TEAM') {
                            return true
                        }
                        
                        else {
                            return false;
                        }
                    });

                    if(teamIsQualified) {
                        tipTeam.correct = true;
                        correctSemiFinal++;
                    }
                    else {
                        tipTeam.correct = false;
                    }

                })
                totalPoints += correctSemiFinal * 12;
                //<--- End SEMI Final -->//


                //<--- Start Final -->//
                let correctFinal = 0;
                this.submitted[curr].tip.knockOutStage["FINAL"].forEach(tipTeam => {
                  const teamIsQualified = this.semi_final.matches.some(team => {
                        if(team.awayTeam.id == tipTeam.id && team.score.winner == 'AWAY_TEAM' || team.homeTeam.id == tipTeam.id && team.score.winner == 'HOME_TEAM') {
                            return true
                        }
                        
                        else {
                            return false;
                        }
                    });

                    if(teamIsQualified) {
                        tipTeam.correct = true;
                        correctFinal++;
                    }
                    else {
                        tipTeam.correct = false;
                    }

                })
                totalPoints += correctFinal * 24;
                //<--- End Final -->//

                //<--- Winner -->//
                let correctWinner = 0;
                this.submitted[curr].tip.knockOutStage["WINNER"].forEach(tipTeam => {


                    if(tipTeam.id === 760) {
                        tipTeam.correct = true;
                        correctWinner++;
                    }
                    else {
                        tipTeam.correct = false;
                    }

                })
                totalPoints += correctWinner * 48;
                //<--- End Winner -->//



                //<--- Goalscorer -->//
                let correctGoalscorer = 0;
                let goalScorers = [
                  'Harry Kane','Schranz', 'Olmo', 'Gakpo', 'Musiala', 'Mikautadze', 'Kane', 'Harry kane', 'Harry kane '
                ];
                let bonus = false;

                this.submitted[curr].tip.knockOutStage["BONUS"].forEach(tipTeam => {


                    if(goalScorers.includes(tipTeam)) {
                        //tipTeam.correct = true;
                        correctGoalscorer++;
                        bonus = true;
                    }
                    else {
                        //tipTeam.correct = false;
                        bonus = false;
                    }

                })
                totalPoints += correctGoalscorer * 32;
                //<--- End Winner -->//

                





                acc.push({
                    ...this.submitted[curr], 
                    groupPoints: correctMatches * 2,
                    lastStage: correctLastStage * 3,
                    quarterFinal: correctQuarterFinal * 6,
                    semi_final: correctSemiFinal * 12,
                    final: correctFinal * 24,
                    winner: correctWinner * 48,
                    points: totalPoints,
                    bonus: bonus
                });
                return acc;
            }, []).sort(
                (a,b) => {
                    return b.points < a.points ? -1 : 1;
                }
            )
        },
        sortedItems: function() {
          return Object.keys(this.submitted);
        } 
      },
      methods: {


      },
      created() {

        Promise.all([
          this.$http.get(process.env.VUE_APP_API + "matches"),
          this.$http.get(process.env.VUE_APP_API + "last"),
          this.$http.get(process.env.VUE_APP_API + "quarter"),
          this.$http.get(process.env.VUE_APP_API + "semi"),
          this.$http.get(process.env.VUE_APP_API + "submitted")
        ])
        .then((responses) => {
          this.group_stage = responses[0].data.data;
          this.last_stage = responses[1].data.data;
          this.quarter_final = responses[2].data.data;
          this.semi_final = responses[3].data.data;
          this.submitted = responses[4].data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });


  

  
      }
    }
  </script>